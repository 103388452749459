import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
	Observable,
	concatMap,
	delayWhen,
	firstValueFrom,
	interval,
	map,
	of,
} from 'rxjs';

import { PermissionApp, PermissionCrudCode } from '@emrm/permissions/types';
import {
	loadPermissions,
	selectPermissions,
	selectPermissionsLoaded,
} from '@emrm/permissions/store';

@Injectable({
	providedIn: 'root',
})
export class PermissionService {
	private userPermission$!: Observable<PermissionApp[] | null>;
	private userPermissionLoaded$!: Observable<boolean | null>;
	private DELAY = 1000;

	constructor(private store: Store) {
		this.store.dispatch(loadPermissions());

		this.userPermission$ = this.store.pipe(select(selectPermissions));
		this.userPermissionLoaded$ = this.store.pipe(
			select(selectPermissionsLoaded),
		);
	}

	/**
	 * Получает разрешение для указанного кода приложения.
	 *
	 * @param appCode Код приложения.
	 * @returns Разрешение для указанного кода приложения или null, если разрешение не найдено.
	 */
	getPermission$(appCode: string) {
		return this.loadPermissionFromStoreWithDelay().pipe(
			map(
				(permission) =>
					permission?.filter((app) => app.code === appCode)[0] || null,
			),
		);
	}

	/**
	 * Проверяет доступ пользователя к приложению.
	 *
	 * @param appCode Код приложения.
	 * @returns Возвращает Observable, который эмитирует значение true, если доступ разрешен, иначе false.
	 */
	hasAccessToApp$(appCode: string) {
		return this.loadPermissionFromStoreWithDelay().pipe(
			map(
				(permission) =>
					this.checkAccessPermissionToApp(permission, appCode).granted,
			),
		);
	}

	/**
	 * Проверяет доступ пользователя к модулю приложения.
	 *
	 * @param appCode Код приложения.
	 * @param moduleCode Код модуля.
	 * @returns Возвращает Promise, который эмитирует значение true, если доступ разрешен, иначе false.
	 */
	hasAccessToModulePromise(appCode: string, moduleCode: string) {
		return firstValueFrom(this.hasAccessToModule$(appCode, moduleCode));
	}

	/**
	 * Проверяет доступ пользователя к модулю приложения.
	 *
	 * @param appCode Код приложения.
	 * @param moduleCode Код модуля.
	 * @returns Возвращает Observable, который эмитирует значение true, если доступ разрешен, иначе false.
	 */
	hasAccessToModule$(appCode: string, moduleCode: string) {
		return this.loadPermissionFromStoreWithDelay().pipe(
			map(
				(permission) =>
					this.checkAccessPermissionToModule(permission, appCode, moduleCode)
						.granted,
			),
		);
	}

	/**
	 * Проверяет доступ пользователя к параметру модуля приложения.
	 *
	 * @param appCode Код приложения.
	 * @param moduleCode Код модуля.
	 * @param optionCode Код параметра.
	 * @returns Возвращает Promise, который эмитирует значение true, если доступ разрешен, иначе false.
	 */
	hasAccessToModuleOptionPromise(
		appCode: string,
		moduleCode: string,
		optionCode: string,
	) {
		return firstValueFrom(
			this.hasAccessToModuleOption$(appCode, moduleCode, optionCode),
		);
	}

	/**
	 * Проверяет доступ пользователя к параметру модуля приложения.
	 *
	 * @param appCode Код приложения.
	 * @param moduleCode Код модуля.
	 * @param optionCode Код параметра.
	 * @returns Возвращает Observable, который эмитирует значение true, если доступ разрешен, иначе false.
	 */
	hasAccessToModuleOption$(
		appCode: string,
		moduleCode: string,
		optionCode: string,
	) {
		return this.loadPermissionFromStoreWithDelay().pipe(
			map(
				(permission) =>
					this.checkAccessPermissionToOption(
						permission,
						appCode,
						moduleCode,
						optionCode,
					).granted,
			),
		);
	}

	/**
	 * Проверяет доступ пользователя к CRUD параметру "Изменение" модуля приложения.
	 *
	 * @param appCode Код приложения.
	 * @param moduleCode Код модуля.
	 * @returns Возвращает Promise, который эмитирует значение true, если доступ разрешен, иначе false.
	 */
	getCrudUpdatePermissionToModulePromise(appCode: string, moduleCode: string) {
		return firstValueFrom(
			this.getCrudUpdatePermissionToModule$(appCode, moduleCode),
		);
	}

	/**
	 * Проверяет доступ пользователя к CRUD параметру "Изменение" модуля приложения.
	 *
	 * @param appCode Код приложения.
	 * @param moduleCode Код модуля.
	 * @returns Возвращает Observable, который эмитирует значение true, если доступ разрешен, иначе false.
	 */
	getCrudUpdatePermissionToModule$(appCode: string, moduleCode: string) {
		return this.loadPermissionFromStoreWithDelay().pipe(
			map((permission) => {
				const accessPermission = this.checkAccessPermissionToModule(
					permission,
					appCode,
					moduleCode,
				);

				const updatePermission =
					accessPermission.modulePermission?.link_crud.find(
						(x) => x.code === PermissionCrudCode.Update,
					);

				if (!updatePermission) {
					return false;
				}

				return updatePermission.status;
			}),
		);
	}

	/**
	 * Загружает разрешения пользователя из хранилища с задержкой.
	 * Возвращает поток, который эмитирует разрешения пользователя после заданной задержки.
	 * Если разрешения уже были загружены, задержка не применяется.
	 *
	 * @returns Поток, эмитирующий разрешения пользователя.
	 */
	private loadPermissionFromStoreWithDelay() {
		return this.userPermissionLoaded$.pipe(
			delayWhen((loaded) => {
				return !loaded ? interval(this.DELAY) : of(undefined);
			}),
			concatMap(() => this.userPermission$),
		);
	}

	/**
	 * Проверяет доступ пользователя к приложению.
	 *
	 * @param permission Права доступа пользователя.
	 * @param appCode Код приложения.
	 * @returns Возвращает { granted: false }, если доступ запрещен,
	 * иначе { granted: true, appPermission },
	 * где:
	 * appPermission - права доступа приложения (необходимо для дальнейших проверок)
	 */
	private checkAccessPermissionToApp(
		permission: PermissionApp[] | null,
		appCode: string,
	) {
		if (!permission) {
			return { granted: false };
		}

		const appPermission = permission?.find((x) => x.code === appCode);

		if (!appPermission) {
			return { granted: false };
		}

		let hasPermissionToAnyModule = false;
		for (const module of appPermission.modules) {
			if (module.status) {
				hasPermissionToAnyModule = true;
				break;
			}
		}

		return {
			appPermission,
			granted: appPermission.status && hasPermissionToAnyModule,
		};
	}

	/**
	 * Проверяет доступ пользователя к модулю приложения.
	 *
	 * @param permission Права доступа пользователя.
	 * @param appCode Код приложения.
	 * @param moduleCode Код модуля.
	 * @returns Возвращает { granted: false }, если доступ запрещен,
	 * иначе { granted: true, appPermission, modulePermission },
	 * где:
	 * appPermission - права доступа приложения,
	 * modulePermission - права доступа модуля (необходимо для дальнейших проверок)
	 */
	private checkAccessPermissionToModule(
		permission: PermissionApp[] | null,
		appCode: string,
		moduleCode: string,
	) {
		const appCheck = this.checkAccessPermissionToApp(permission, appCode);

		if (!appCheck.granted) {
			return { granted: false };
		}

		const modulePermission = appCheck.appPermission?.modules.find(
			(x) => x.code === moduleCode,
		);

		if (!modulePermission) {
			return { granted: false };
		}

		return {
			appPermission: appCheck.appPermission,
			modulePermission: modulePermission,
			granted: modulePermission.status || false,
		};
	}

	/**
	 * Проверяет доступ пользователя к параметру модуля приложения.
	 *
	 * @param permission Права доступа пользователя.
	 * @param appCode Код приложения.
	 * @param moduleCode Код модуля.
	 * @param optionCode Код параметра.
	 * @returns Возвращает { granted: false }, если доступ запрещен,
	 * иначе { granted: true, appPermission, modulePermission },
	 * где:
	 * appPermission - права доступа приложения,
	 * modulePermission - права доступа модуля,
	 * optionPermission - права доступа параметра (необходимо для дальнейших проверок)
	 */
	private checkAccessPermissionToOption(
		permission: PermissionApp[] | null,
		appCode: string,
		moduleCode: string,
		optionCode: string,
	) {
		const moduleCheck = this.checkAccessPermissionToModule(
			permission,
			appCode,
			moduleCode,
		);

		if (!moduleCheck.granted) {
			return { granted: false };
		}

		const optionPermission = moduleCheck.modulePermission?.link_options.find(
			(x) => x.code === optionCode,
		);

		if (!optionPermission) {
			return { granted: false };
		}

		return {
			appPermission: moduleCheck.appPermission,
			modulePermission: moduleCheck.modulePermission,
			optionPermission: optionPermission,
			granted: optionPermission.status || false,
		};
	}
}
